<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Creation time</th>
                <td>{{ message.creationTime | formatDateTime }}</td>
              </tr>
              <tr>
                <th>Sent on</th>
                <td v-if="message.sentOn">
                  {{ message.sentOn | formatDateTime }}
                </td>
                <td
                  v-else
                  class="table-warning"
                >
                  <strong>Not sent yet</strong>
                </td>
              </tr>
              <tr v-if="message.sendingError">
                <th>Sending error</th>
                <td>{{ message.sendingError }}</td>
              </tr>
              <tr>
                <th>To</th>
                <td>{{ message.to }}</td>
              </tr>
              <tr>
                <th>Category</th>
                <td>{{ message.category }}</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <template #header>
        <b-card-title>Content</b-card-title>
        <b-button
          v-b-tooltip.hover
          size="sm"
          title="Download as .eml"
          @click="download"
        >
          <feather-icon icon="MailIcon" />
          Download
        </b-button>
      </template>
      <b-card-body>
        <div
          class="emailHtmlContent"
          v-html="cleanedMessageContent"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BCardTitle, BCardBody, BButton, VBTooltip,
} from 'bootstrap-vue'
import MessageService from '@/service/message.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      message: {},
    }
  },
  computed: {
    messageId() {
      return this.$route.params.id
    },
    cleanedMessageContent() {
      if (!this.message.content) {
        return null
      }

      let htmlContent = this.message.content

      // rename class .content from email content, because this class is also used in portal ui and may cause some design issues
      htmlContent = htmlContent.replace('.content', '.email-content')
      return htmlContent
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      MessageService.getAsync(this.messageId)
        .then(result => {
          this.message = result
        })
    },
    download() {
      MessageService.downloadAsync(this.messageId, { disableTenantFilter: true })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          const url = window.URL.createObjectURL(new Blob([result.data]))
          const link = document.createElement('a')
          link.href = url

          if (result.headers['content-type'] === 'application/vnd.ms-outlook') {
            link.setAttribute('download', `${this.messageId}.eml`)
          } else {
            link.setAttribute('download', `${this.messageId}.txt`)
          }
          document.body.appendChild(link)
          link.click()
          link.remove()// you need to remove that elelment which is created before.
        })
    },
  },
}
</script>

<style scoped>
  .emailHtmlContent {
    overflow: scroll;
    overflow-y: hidden;
  }
</style>
