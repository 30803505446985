// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {

  async getAsync(messageId, config) {
    const result = await axios.get(`/message-management/messages/${messageId}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async getListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get('/message-management/messages', requestConfig)
    return result.data
  },

  async downloadAsync(messageId, config) {
    const result = await axios.get(
      `/message-management/messages/${messageId}/download`,
      buildAxiosRequestConfig(
        config,
        requestConfig => {
          // eslint-disable-next-line no-param-reassign
          requestConfig.responseType = 'arraybuffer'
        },
      ),
    )

    return result
  },

}
